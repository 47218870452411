<template>
  <ui-component-modal
    :modalTitle="$t('Components.Terms.ModalActivateBookingTerm.Main_Title')"
    :isSaving="isActivating"
    :isSavingSuccess="isActivateSuccess"
    :isSavingError="isActivateError"
    :hideFooterButtons="isActivateError || isActivateSuccess"
    :disableSaveButton="!isActivateConfirmed || isActivating"
    :onClickCancel="onClickCancel"
    :onClickSave="save"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="has-margin-bottom is-size-5 has-text-centered">
        {{ $t('Components.Terms.ModalActivateBookingTerm.Message_Warning') }}
      </div>
      <div class="has-text-centered">
        <BaseCheckbox
          v-model="isActivateConfirmed"
          class="has-text-left"
          :label="
            $t('Components.Terms.ModalActivateBookingTerm.Text_YesImSure')
          "
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import termsProvider from '@/providers/terms'

const BaseCheckbox = () => import('@/components/UI/Form/BaseCheckbox')

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    bookingTerm: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isActivateConfirmed: false,
      isActivating: false,
      isActivateSuccess: false,
      isActivateError: false,
    }
  },

  computed: {},

  created() {},

  methods: {
    save() {
      let self = this

      if (!self.isActivating) {
        self.isActivating = true

        termsProvider.methods
          .updateActivateTerm(self.bookingTerm.Id)
          .then((response) => {
            if (response.status === 200) {
              // Update reservation store
              self.isActivateSuccess = true

              setTimeout(() => {
                self.$emit('termActivated')
                this.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            self.isActivateError = true
          })
          .finally(() => {
            self.isActivating = false
          })
      }
    },
  },
}
</script>
